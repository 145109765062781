import {
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
  IonList,
  IonListHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonIcon,
  IonBadge,
  IonModal,
} from "@ionic/react";
import { cartOutline } from "ionicons/icons";
import React, { useState } from "react";
import Cart from "../../Cart";

const ReviewStep = ({
  selectedItems,
  setSelectedItems,
  formData,
  setFormData,
}) => {
  const calculateItemTotal = (price, quantity) => parseFloat(price) * quantity;
  const calculateTotalWithTax = (total) => total * 1.0725; // 7.25% tax rate
  const [showCartModal, setShowCartModal] = useState(false);

  const removeSelectedItem = (id) => {
    const updatedItems = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(updatedItems);
    setFormData((prevData) => ({
      ...prevData,
      selectedItems: updatedItems,
    }));
  };

  const updateItemQuantity = (id, newQuantity) => {
    setSelectedItems(
      selectedItems.map((item) =>
        item.id === id ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  let grandTotal = 0;


  return (
    <IonRow className="h-full w-full">
      <IonModal
        isOpen={showCartModal}
        onDidDismiss={() => setShowCartModal(false)}
      >
        <Cart
          selectedItems={selectedItems}
          setShowCartModal={setShowCartModal}
          updateItemQuantity={updateItemQuantity}
          removeSelectedItem={removeSelectedItem}
        />
      </IonModal>
      <IonCol size="12" className="h-full overflow-auto">
        <IonCard className="shadow-none">
          <IonCardHeader>
            <IonCardSubtitle className="capitalize">
              {formData.serviceType}
            </IonCardSubtitle>
            <IonCardTitle className="capitalize">Order Summary</IonCardTitle>
            <div className="absolute right-0 top-0">
              <IonButton
                size="large"
                shape="round"
                color="tertiary"
                onClick={() => setShowCartModal(true)}
              >
                <IonIcon
                  slot="icon-only"
                  color="dark"
                  icon={cartOutline}
                ></IonIcon>
              </IonButton>
              <IonBadge
                shape="rounded"
                className="absolute right-0 top-0 rounded-full flex items-center justify-center aspect-square w-5"
              >
                {selectedItems.length}
              </IonBadge>
            </div>
          </IonCardHeader>

          <IonList className="border bg-gray-50 w-full">
            <IonListHeader className="font-bold mb-2 text-center">
              Contact Details
            </IonListHeader>
            <IonItem>
              {formData.orderName && <li>Order Name: {formData.orderName}</li>}
            </IonItem>
            <IonItem>
              {formData.phoneNumber && (
                <li>Phone Number: {formData.phoneNumber}</li>
              )}
            </IonItem>
            <IonItem>
              {formData.email && <li>Email: {formData.email}</li>}
            </IonItem>
          </IonList>

          {/* Conditionally Render Pickup or Catering Details */}
          {formData.serviceType === "Pickup" && (
            <IonList className="border bg-gray-50 w-full">
              <IonListHeader className="font-bold mb-2 text-center">
                Pickup Details
              </IonListHeader>
              <IonItem>
                {formData.pickupTime && (
                  <span>Pickup Time: {formData.pickupTime}</span>
                )}
              </IonItem>
              <IonItem>
                {formData.tempType && (
                  <span>Temperature: {formData.tempType}</span>
                )}
              </IonItem>
              <IonItem>
                {formData.date && (
                  <span>Pickup Date: {formData.date}</span>
                )}
              </IonItem>
            </IonList>
          )}

          {formData.serviceType === "Catering" && (
            <IonList className="border bg-gray-50 w-full">
              <IonListHeader className="font-bold mb-2 text-center">
                Catering Details
              </IonListHeader>
              <IonItem>
                {formData.date && (
                  <span>Event Date: {formData.date}</span>
                )}
              </IonItem>
              <IonItem>
                {formData.street && (
                  <span>
                    Address: {formData.street}, {formData.city}
                  </span>
                )}
              </IonItem>
            </IonList>
          )}

          <IonList className="border bg-gray-50 w-full">
            <IonListHeader className="font-bold mb-2 text-center">
              Cart Items
            </IonListHeader>
            {selectedItems.map((item) => {
              const itemTotal = calculateItemTotal(item.price, item.quantity);
              grandTotal += itemTotal;

              return (
                <IonItem key={item.id}>
                  {item.name} (x{item.quantity}) - ${itemTotal.toFixed(2)}
                </IonItem>
              );
            })}
          </IonList>

          <IonList className="border w-full">
            <IonListHeader className="text-center flex flex-col items-center py-4">
              <h2 className="font-bold text-center">
                Subtotal: ${grandTotal.toFixed(2)}
              </h2>
              <h4 className="font-bold text-lg text-center">
                Total w/ Tax: ${calculateTotalWithTax(grandTotal).toFixed(2)}
              </h4>
            </IonListHeader>
            <IonItem lines="none" className="p-2">
              <IonLabel position="floating">Additional Comments</IonLabel>
              <IonTextarea
                placeholder="Any additional notes for order"
                className="bg-white"
                value={formData.comments || ""}
                onIonChange={(e) =>
                  setFormData({ ...formData, comments: e.detail.value })
                }
              />
            </IonItem>
          </IonList>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};
export default ReviewStep;
