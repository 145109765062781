import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonModal,
  IonRouterLink,
  IonRow,
  IonText,
  IonContent,
  IonSpinner,
} from "@ionic/react";
import { call, logoFacebook, logoInstagram, logoYoutube } from "ionicons/icons";
import lasagna from "../../../assets/Images/background.jpg";
import Contact from "../../../components/Views/Contact";
import Catering from "../../../components/Catering";
import logo from "../../../assets/Logos/marcellas-logo.png";

function Home({ setActiveTab }) {
  const now = new Date();
  const dayOfWeek = now.getDay();
  const hour = now.getHours();
  const isOpen = dayOfWeek >= 2 && dayOfWeek <= 6 && hour >= 10 && hour < 14;
  const [showContactModal, setShowContactModal] = useState(false);
  const [showCateringModal, setShowCateringModal] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Image loading state


  return (
    <IonContent scroll-y="false">
      {!isImageLoaded && (
        // Show a spinner while the image is loading
        <IonGrid className="h-full z-50">
          <IonRow className="h-full ion-justify-content-center">
            <IonCol size="auto" className=" flex items-center justify-center">
              <div className="loader">
                <IonSpinner name="crescent" />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}

      <IonGrid
        className="bg-slate-100 bg-cover bg-center p-0 m-0 ion-text-center ion-justify-content-between h-full"
        style={{ backgroundImage: `url(${lasagna})` }}
      >
        {/* Hidden image to trigger loading */}
        <img
          src={lasagna}
          alt="background"
          className="hidden"
          onLoad={() => setIsImageLoaded(true)}
        />

        {isImageLoaded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="w-full h-full"
          >
            <IonRow className="container mx-auto max-w-md ion-justify-content-center ion-align-content-center px-2">
              <IonCol
                size="12"
                className=" bg-mYellow flex items-center justify-center gap-2"
              >
                <div>
                  <span className="pl-1 text-xs">
                    1099 Tennesee St, San Francisco
                  </span>
                </div>
                <div>
                  <span className="pl-2 text-xs">415-920-2225 </span>
                </div>
              </IonCol>
              <IonCol size="6" className="bg-white rounded-bl-lg p-0">
                <IonText className="text-sm font-bold"></IonText>
                <IonText color="secondary">
                  <p>Tuesday - Saturday</p>
                </IonText>
                <IonText className="text-xs">
                  <p>10am-2pm</p>
                </IonText>
              </IonCol>
              <IonCol
                size="6"
                className={`rounded-br-lg text-white font-bold flex items-center justify-center p-0 ${
                  isOpen ? "bg-mGreen" : "bg-red-600"
                }`}
              >
                {isOpen ? "We're Open" : "We're Closed"}
              </IonCol>
              <IonCol
                size="6"
                className="flex mx-auto items-center justify-center gap-2 col-span-3 row-span-2 p-2"
              >
                <IonRouterLink
                  href="https://www.facebook.com/marcellaslasagneria/"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-[#4267B2] text-white drop-shadow-xl rounded-md h-full aspect-square flex items-center justify-center"
                >
                  <IonIcon size="small" icon={logoFacebook}></IonIcon>
                </IonRouterLink>

                <IonRouterLink
                  href="https://www.youtube.com/channel/UCvSzZgB-27Llh-IllTqGL7Q"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-[#FF0000] text-white drop-shadow-xl rounded-md h-full aspect-square flex items-center justify-center p-1"
                >
                  <IonIcon size="medium" icon={logoYoutube}></IonIcon>
                </IonRouterLink>

                <IonRouterLink
                  href="https://www.instagram.com/marcellaslasagneriasf/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                  className="instagram-logo text-white drop-shadow-xl rounded-md h-full aspect-square flex items-center justify-center"
                >
                  <IonIcon icon={logoInstagram}></IonIcon>
                </IonRouterLink>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="10" sizeMd="6" sizeLg="4" className="mx-auto">
                <img
                  className="bg-white rounded-lg w-full border-4 border-mYellow"
                  src={logo}
                  alt="logo-marcellas"
                ></img>
              </IonCol>
            </IonRow>

            {/* Footer with Events & Catering Button */}
            <IonRow className="absolute w-full mx-auto bottom-0 ion-justify-content-center drop-shadow-none">
              <IonCol
                size="12"
                sizeSm="12"
                sizeMd="6"
                sizeLg="4"
                className="mx-auto container flex justify-center gap-4"
              >
                <IonButton
                  color="primary"
                  expand="block"
                  size="medium"
                  onClick={() => setShowCateringModal(true)}
                >
                  <IonText className="font-bold">Catering Delivered</IonText>
                </IonButton>
                <IonButton
                  href="tel:+14159202225"
                  color="danger"
                  shape="round"
                  size="large"
                >
                  <IonIcon slot="icon-only" icon={call}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>

            {/* Events Modal */}
            <IonModal
              isOpen={showCateringModal}
              onDidDismiss={() => setShowCateringModal(false)}
            >
              <Catering
                setShowCateringModal={setShowCateringModal}
                setActiveTab={setActiveTab}
              />
            </IonModal>

            <IonModal
              isOpen={showContactModal}
              onDidDismiss={() => setShowContactModal(false)}
            >
              <Contact setShowContactModal={setShowContactModal} />
            </IonModal>
          </motion.div>
        )}
      </IonGrid>
    </IonContent>
  );
}

export default Home;
