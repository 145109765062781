import React, { useState } from "react";
import {
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
  IonContent,
  IonCard,
} from "@ionic/react";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import { useMenu } from "../../../context/MenuContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  ReviewStep,
  MenuSelectionStep,
  PickupInfoStep,
  BasicInfoStep,
  CateringInfoStep,
  StartPreorderStep,
} from "../../../components/Layout/Steps";
import ConfirmationStep from "../Steps/ConfirmationStep";

const Preorder = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [date, setDate] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [errors, setErrors] = useState({});
  const { cateringMenu, loading, error } = useMenu();
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  const [categories, setCategories] = useState([
    {
      key: Date.now(),
      items: [{ key: Date.now(), value: "", quantity: 1 }],
      selectedCategory: "",
    },
  ]);

  const [formData, setFormData] = useState({
    orderName: "",
    phoneNumber: "",
    email: "",
    serviceType: "",
    pickupTime: "",
    tempType: "",
    comments: "",
    selectedItems: [],
    date: null, // Consolidated date field
    street: "",
    city: "",
    state: "California",
  });

  const next = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const validateStep = (step) => {
    let isValid = true;
    const newErrors = {};

    switch (step) {
      case 1:
        if (!formData.orderName) {
          newErrors.orderName = "Order Name is required";
          toast.error("Order Name is required");
          isValid = false;
        }
        if (!formData.phoneNumber) {
          newErrors.phoneNumber = "Phone Number is required";
          toast.error("Phone Number is required");
          isValid = false;
        }
        if (!formData.email) {
          newErrors.email = "Email is required";
          toast.error("Email is required");
          isValid = false;
        }
        if (!formData.serviceType) {
          newErrors.serviceType = "Service type is required";
          toast.error("Service type is required");
          isValid = false;
        }
        break;

      case 2:
        if (formData.serviceType === "Pickup" || formData.serviceType === "Catering") {
          if (!formData.date) {
            newErrors.date = `${formData.serviceType} date is required`;
            toast.error(`${formData.serviceType} date is required`);
            isValid = false;
          }
          if (formData.serviceType === "Pickup" && !formData.pickupTime) {
            newErrors.pickupTime = "Pickup time is required";
            toast.error("Pickup time is required");
            isValid = false;
          }
          if (formData.serviceType === "Pickup" && !formData.tempType) {
            newErrors.tempType = "Temperature preference is required";
            toast.error("Temperature preference is required");
            isValid = false;
          }
          if (formData.serviceType === "Catering" && !formData.street) {
          newErrors.tempType = "Street is required for delivery";
          toast.error("Street is required");
          isValid = false;
          }
          if (formData.serviceType === "Catering" && !formData.city) {
            newErrors.tempType = "Street is required for delivery";
            toast.error("Street is required");
            isValid = false;
          }
        }
        break;

      case 3:
        if (selectedItems.length === 0) {
          newErrors.items = "At least one item must be selected";
          toast.error("At least one item must be selected");
          isValid = false;
        }
        break;

      default:
        break;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateStep(currentStep)) return;

    const categories = formData.selectedItems.map(item => ({
      category: item.category || "N/A",
      items: item.name || "N/A",
    }));

    const templateParams = {
      orderName: formData.orderName || "",
      phoneNumber: formData.phoneNumber || "",
      email: formData.email || "",
      serviceType: formData.serviceType || "",
      pickupDate: formData.serviceType === "Pickup" ? formData.date || "N/A" : "N/A",
      pickupTime: formData.serviceType === "Pickup" ? formData.pickupTime || "N/A" : "N/A",
      tempType: formData.serviceType === "Pickup" ? formData.tempType || "N/A" : "N/A",
      cateringDate: formData.serviceType === "Catering" ? formData.date || "N/A" : "N/A",
      street: formData.street || "N/A",
      city: formData.city || "N/A",
      state: formData.state || "California",
      deliveryDate: formData.serviceType === "Delivery" ? formData.date || "N/A" : "N/A",
      deliveryTime: formData.serviceType === "Delivery" ? formData.deliveryTime || "N/A" : "N/A",
      comments: formData.comments || "No additional comments",
      categories,
    };

    emailjs
      .send("service_marcellas", "template_mi55j1p", templateParams, "OXVmMrXHHOEpr832j")
      .then((response) => {
        setSubmitted(true);
        setEmailLoading(false);
        next();
      })
      .catch((err) => {
        setEmailLoading(false);
        toast.error("Failed to submit order.");
      });
  };

  const resetForm = () => {
    setCurrentStep(0);
    setDate(null);
    setSelectedItems([]);
    setCategories([
      {
        key: Date.now(),
        items: [{ key: Date.now(), value: "", quantity: 1 }],
        selectedCategory: "",
      },
    ]);
    setFormData({
      orderName: "",
      phoneNumber: "",
      email: "",
      serviceType: "",
      pickupTime: "",
      tempType: "",
      comments: "",
      selectedItems: [],
      date: null,
      street: "",
      city: "",
      state: "California",
    });
    setErrors({});
  };

  const steps = [
    {
      title: "Start Order",
      content: (
        <StartPreorderStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
      ),
    },
    {
      title: "Basic",
      content: (
        <BasicInfoStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
      ),
    },
    {
      title: formData.serviceType === "Pickup" ? "Pickup" : "Catering",
      content:
        formData.serviceType === "Pickup" ? (
          <PickupInfoStep
            formData={formData}
            setFormData={setFormData}
            date={date}
            setDate={setDate}
            errors={errors}
          />
        ) : (
          <CateringInfoStep
            formData={formData}
            setFormData={setFormData}
            date={date}
            setDate={setDate}
            errors={errors}
          />
        ),
    },
    {
      title: "Menu",
      content: (
        <MenuSelectionStep
          cateringMenu={cateringMenu}
          categories={categories}
          setCategories={setCategories}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setFormData={setFormData}
          formData={formData}
          loading={loading}
          error={error}
          errors={errors}
        />
      ),
    },
    {
      title: "Review",
      content: (
        <ReviewStep
          selectedItems={selectedItems}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: "Confirmation",
      content: (
        <ConfirmationStep
          emailLoading={emailLoading}
          submitted={submitted}
          onRetry={handleSubmit}
          onHome={() => history.push("/")}
          resetForm={resetForm}
        />
      ),
    },
  ];

  return (
    <IonContent color="light" scroll-y="false">
      <IonGrid className="p-0 m-0 h-full md:py-2 relative">
        <IonCard className="h-full m-0 max-w-xl mx-auto md:rounded-xl">
          <ToastContainer
            autoClose={3000}
            className="absolute top-30 left-1/2 z-20"
          />

          <IonRow className="bg-slate-50 border-b border-slate-200 px-4 ion-justify-content-between ion-align-items-center h-[8%]">
            {/* Step Header Code */}
          </IonRow>
          <div className="h-[84%] ion-align-items-center ion-justify-content-center relative">
            {steps[currentStep].content}
          </div>
          <IonRow className="ion-align-items-center ion-justify-content-center h-[8%] bg-slate-50 flex items-center justify-center">
            {currentStep > 0 && currentStep < steps.length - 1 && (
              <IonCol size="auto">
                <IonButton
                  color="danger"
                  onClick={prev}
                  shape="round"
                  size="small"
                >
                  Back
                </IonButton>
              </IonCol>
            )}
            {currentStep === steps.length - 2 && (
              <IonCol size="auto">
                <IonButton
                  color="primary"
                  onClick={handleSubmit}
                  shape="round"
                  size="small"
                >
                  Order
                </IonButton>
              </IonCol>
            )}
            {currentStep < steps.length - 2 && (
              <IonCol size="auto">
                <IonButton
                  color="primary"
                  onClick={next}
                  shape="round"
                  size="small"
                >
                  Next
                </IonButton>
              </IonCol>
            )}
            {currentStep === steps.length - 1 && (
              <IonCol size="auto">
                <IonButton
                  onClick={() => {
                    resetForm();
                  }}
                  color="primary"
                >
                  Start New Order
                </IonButton>
              </IonCol>
            )}
          </IonRow>
        </IonCard>
      </IonGrid>
    </IonContent>
  );
};

export default Preorder;
